import React from "react";
import styled from "@emotion/styled";
// components
import { DefaultLayout } from "../components/layout/defaultLayout";
import { PageHeading } from "../components/heading/pageHeading";
import { BreadcrumbBar } from "../components/bar/breadcrumbBar";
import { SectionHeading } from "../components/heading/sectionHeading";
import { ItemCard } from "../components/card/itemCard";
// utils
import { ItemLists } from "../utils/itemLists";
// breadcrumb
const breadcrumbs = [
  { txt: "トップ", url: "/" },
  { txt: "ピックアップ製品", url: "/pickup" },
];
// sale === trueを抽出
const PickupItemLists = ItemLists.filter((Item) => {
  return Item.pickup === true;
});

const PickupPage = () => {
  return (
    <DefaultLayout pageTitle="ピックアップ製品">
      <PageHeading enTxt="PICK UP" jpTxt="ピックアップ製品" />
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <SSection>
        <SContainer>
          <SectionHeading txt="ピックアップ中の製品" />
          <SWrapper>
            {PickupItemLists.map(({ id, name, price, brand, url, img }) => {
              return (
                <ItemCard
                  key={id}
                  name={name}
                  price={price}
                  brand={brand}
                  url={url}
                  img={img}
                />
              );
            })}
          </SWrapper>
        </SContainer>
      </SSection>
    </DefaultLayout>
  );
};

export default PickupPage;

const SSection = styled.section`
  padding: 4rem 0;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
`;

const SWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
  gap: 1rem;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(auto-fit, minmax(min(160px, 100%), 1fr));
    gap: 0.5rem;
  }
`;
